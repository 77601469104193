import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from '@/store/store.js'
import './assets/css/font-awesome.min.css'
import './assets/css/style.css'
import AMap from 'vue-amap'
Vue.config.productionTip = false
Vue.use(AMap)
    // import VueAmpTemplate from 'vue-amp-template';
    // Vue.use(VueAmpTemplate);
AMap.initAMapApiLoader({
    // 申请的高德key
    key: 'b26b7c08a5230144460c3c164f61d619',
    // 插件集合
    plugin: ['AMap.ToolBar']
});
new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')