import axios from "axios";
const _host = "cmsauth.baowugroup.com";
const _port = "";
const _domainid = 74;
const _protocol = window.location.protocol
    // import CryptoJS from 'crypto-js'
const CryptoJS = require("crypto-js");
const cryptoObject = CryptoJS.AES.encrypt('data', 'key');
console.log('CryptoJS:', cryptoObject);
//随机数生成
function RondomPass(number) {
    var arr = [];
    var arr1 = Array.of("0", "1", "2", "3", "4", "5", "6", "7", "8", "9");
    for (var i = 0; i < number; i++) {
        var n = Math.floor(Math.random() * 10);
        arr[i] = arr1[n]
    }
    return arr.join("")
}

//UTC时间
function UTCTime() {
    return parseInt(new Date().getTime() / 1000);
}
axios.defaults.timeout = 6000;
// axios.defaults.withCredentials=true;//设置携带证书
// axios.defaults.baseURL = '';
let cancel,
    promiseArr = {};
//http request 拦截器
axios.interceptors.request.use(
    config => {
        config.headers['Content-Type'] = 'application/json';
        let xNonce = RondomPass(10)
        let xDate = UTCTime()
        let contentMd5 = CryptoJS.MD5(JSON.stringify(config.data ? config.data : '')).toString(CryptoJS.enc.Hex)
        let signString = '',
            userHash = '',
            passHash = '13ade1de1eff43ffb821735f352a4148';
        userHash = `0/56f5cff3cad14853a44782c2c689ab2a`
        passHash = '13ade1de1eff43ffb821735f352a4148'
        config.headers['x-user'] = userHash;
        config.headers['x-nonce'] = xNonce
        config.headers['x-date'] = xDate
        config.headers['Content-Md5'] = contentMd5
        signString = `${config.method.toUpperCase()}\n${config.url.replace(_protocol + '//' + _host  + _port,'')}\nx-user:${userHash}\nx-nonce:${xNonce}\nx-date:${xDate}\nContent-Md5:${contentMd5}\n`
        let signatureString = CryptoJS.HmacSHA1(signString, passHash).toString().toUpperCase()
        config.timeout = 100000
        config.headers['x-signature'] = signatureString
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

//http response 拦截器
axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        return Promise.reject(error);
    }
);

/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */
export function fetch(url, params) {
    let requestUrl = _protocol + '//' + _host + _port + url + "?domainId=" + _domainid;
    return new Promise((resolve, reject) => {
        axios
            .get(
                requestUrl, { withCredentials: true }, {
                    params: params
                }
            )
            .then(response => {
                resolve(response.data);
            })
            .catch(err => {
                reject(err);
            });
    });
}
export function fetchddh(url, params) {
    let requestUrl = _protocol + '//' + _host + _port + url + "?domainId=" + _domainid;
    return new Promise((resolve, reject) => {
        axios
            .get(
                requestUrl, { withCredentials: true }, {
                    params: params
                }
            )
            .then(response => {
                resolve(response.data);
            })
            .catch(err => {
                reject(err);
            });
    });
}

export function fetchGp(url, params) {
    let requestUrl = url;
    return new Promise((resolve, reject) => {
        axios
            .get(requestUrl, {
                params: params
            })
            .then(response => {
                resolve(response.data);
            })
            .catch(err => {
                reject(err);
            });
    });
}
/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function post(url, data) {
    let requestUrl = _protocol + '//' + _host + _port + url + "?domainId=" + _domainid;
    return new Promise((resolve, reject) => {
        axios
            .post(requestUrl, data, { withCredentials: true })
            .then(response => {
                resolve(response.data);
            })
            .catch(err => {
                reject(err);
            });
    });
}
export function postddh(url, data) {
    let requestUrl = _protocol + '//' + _host + _port + url + "?domainId=" + _domainid;
    return new Promise((resolve, reject) => {
        axios
            .post(requestUrl, data, { withCredentials: true })
            .then(response => {
                resolve(response.data);
            })
            .catch(err => {
                reject(err);
            });
    });
}

/**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function patch(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.patch(url, data).then(
            response => {
                resolve(response.data);
            },
            err => {
                reject(err);
            }
        );
    });
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function put(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.put(url, data).then(
            response => {
                resolve(response.data);
            },
            err => {
                reject(err);
            }
        );
    });
}

// 获取主机信息 用于识别站点
export const API_GetDomainInfo = params => {
    return fetch("/domain", params);
};
// 获取菜单信息
export const API_GetMenuList = params => {
    return fetch("/v1/web/api/node/list", params);
};
// 获取二级菜单列表
export const API_GetSecMenuCon = params => {
    return fetch("/v1/web/api/node/" + params.conId + "/list", {
        pageNo: params.pageNo,
        pageSize: params.pageSize
    });
};
// 查询子栏目
export const API_GetChildMenuCon = params => {
    return fetch("/v1/web/api/node/" + params.nodeId + "/list", {
        params: {
            parentId: params.parentId
        }
    });
};
// 获取宝武新闻列表
export const API_GetNewsList = params => {
    return post("/v1/web/api/content/listAll", {
        pageNo: params.pageNo,
        pageSize: params.pageSize,
        condition: { nodeId: params.nodeId, keyword: params.keyword || '', title: params.keyword || '' }
    });
};
// 新闻推荐列表
export const API_GetNewsListRecommend = params => {
    return post("/v1/web/api/content/listAll", {
        pageNo: params.pageNo,
        pageSize: params.pageSize,
        condition: { nodeId: params.nodeId, isRecommend: 1 }
    });
};
// 新闻热点列表
export const API_GetNewsListHot = params => {
    return post("/v1/web/api/content/listAll", {
        pageNo: params.pageNo,
        pageSize: params.pageSize,
        condition: { nodeId: params.nodeId, isHot: 1 }
    });
};
export const API_GetNewsListddh = params => {
    return postddh("/v1/web/api/content/listAll", {
        pageNo: params.pageNo,
        pageSize: params.pageSize,
        condition: { nodeId: params.nodeId }
    });
};
export const API_getNewsListAll = params => {
    return postddh("/v1/web/api/content/listAll", params);
};
export const API_GetNewsListNode = params => {
    return postddh("/v1/web/api/content/listAll", {
        pageNo: params.pageNo,
        pageSize: params.pageSize,
        nodeId: params.nodeId
    });
};
// 获取宝武新闻列表
export const API_GetNewsNodeListAll = params => {
    return fetch("/v1/web/api/node/" + params.nodeId + "/listAll");
};
// 获取文章内容
export const API_GetNewsCon = params => {
    return fetch("/v1/web/api/content/" + params.contentId);
};

// 获取股票
export const API_shareTick = params => {
    return fetch("/v1/api/shareTick");
};

// 获取文章内容
export const API_GetNewsConddh = params => {
    return fetchddh("/v1/web/api/content/" + params.contentId);
};
// 获取信息反馈验证码
export const API_GetMessageCheckCode = (params = {}) => {
    return fetch("/v1/api/feedback/verifycode");
};
// 添加信息反馈
export const API_SendMessage = (params = {}) => {
    return post("/v1/api/feedback/add", params);
};
// 获取搜索内容
export const API_GetSearchList = params => {
    return post("/v1/web/api/content/search", {
        pageNo: params.pageNo,
        pageSize: params.pageSize,
        condition: { keyword: params.keyword, nodeIds: params.nodeIds }
    });
};
// 获取首页第一条图片新闻的图片
export const API_GetIndexNewsFirstImg = params => {
    return post("/v1/web/api/content/search", {
        pageNo: params.pageNo,
        pageSize: params.pageSize,
        condition: { isColor: params.isColor }
    });
};
//获取股票数据
export const API_GetGuPiaoDatas = (params = {}) => {
    return fetchGp(
        "https://api.shenjian.io/?appid=0c1da8a8bbd1dccecc8e190c3aa28395&codes=600019,600581,600845,000717,601968"
    );
};
// 栏目下文章
export const API_contentList = params => {
    return post("/v1/web/api/content/list", {
        pageNo: params.pageNo,
        pageSize: params.pageSize,
        condition: { nodeId: params.nodeId, addDate: params.addDate || '' }
    });
};
// 添加需求单
export const API_addDemandForm = params => { return post("/whhgjg/web/addDemandForm", params) }

// 添加订购单
export const API_addOrderForm = params => { return post("/whhgjg/web/addOrderForm", params) }

// 验证码图标
export const API_getVerifyCode = params => { return post("/whhgjg/web/getBase64VerifyCode", params) }

export const getRandomInt = (min, max) => {
    min = Math.ceil(min); // 确保min是整数
    max = Math.floor(max); // 确保max是整数
    return Math.floor(Math.random() * (max - min + 1)) + min; // 返回介于min和max之间的整数
}