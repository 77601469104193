import Vuex from 'vuex'
import Vue from 'vue'
import { API_GetMenuList } from '@/fetch/api'
import { mapActions } from 'vuex';
Vue.use(Vuex)
const def = store => {
    // store.dispatch('getMenuList')
}
export default new Vuex.Store({
    state: {
        menuList: [],
        firstLevelMenuList: [],
        firstLevelMenuListById: {},
        secondLevelMenuListById: {},
        searchText: 'wwwwwwww',
        netInfosArr: []
    },
    getters: {
        menuList: state => {
            return state.menuList
        },
        firstLevelMenuList: state => {
            return state.firstLevelMenuList
        },
        firstLevelMenuListById: state => {
            return state.firstLevelMenuListById
        },
        secondLevelMenuListById: state => {
            return state.secondLevelMenuListById
        },
    },
    actions: {
        getMenuList: ({ dispatch, commit, state }) => {
            API_GetMenuList().then(success => {
                commit('setMenuList', success.data)
            }).catch(error => {

            })
        },
    },
    mutations: {
        setMenuList: (state, playload) => {
            // 取出首页菜单
            state.menuList = playload && playload.length && playload[0].childs || []
            state.firstLevelMenuList = []
            state.firstLevelMenuListById = {}
            state.secondLevelMenuListById = {}
            for (let i = 0; i < state.menuList.length; i++) {
                state.firstLevelMenuList.push(state.menuList[i])
                state.firstLevelMenuListById[state.menuList[i].nodeId] = state.menuList[i]
            }
            localStorage.setItem('storeState', JSON.stringify({
                menuList: state.menuList,
                firstLevelMenuList: state.firstLevelMenuList,
                firstLevelMenuListById: state.firstLevelMenuListById,
                secondLevelMenuListById: state.secondLevelMenuListById
            }))

        },
        dataObj: (state, datas) => {
            state.menuList = datas.menuList,
                state.firstLevelMenuList = datas.firstLevelMenuList,
                state.firstLevelMenuListById = datas.firstLevelMenuListById,
                state.secondLevelMenuListById = datas.secondLevelMenuListById
            localStorage.setItem('storeState', JSON.stringify({
                menuList: datas.menuList,
                firstLevelMenuList: datas.firstLevelMenuList,
                firstLevelMenuListById: datas.firstLevelMenuListById,
                secondLevelMenuListById: datas.secondLevelMenuListById
            }))
        },

        setSearchText: (state, playload) => {
            // 设置搜索文字
            // state.searchText = playload
        }
    },
    plugins: [def]
})