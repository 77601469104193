<template>
    <div id="app">
        <hsHeader />
        <div class="layout">
            <transition name="fade" :duration="100">
                <router-view />
            </transition>
        </div>
        <hsFooter />
    </div>
</template>

<script>
import hsHeader from '@/components/public/heads.vue'
import hsFooter from '@/components/public/footers.vue'
export default {
    name: 'App',
    provide () {
        return {
            
        }
    },
    data () {
        return {
            data:'',
        }
    },
    components: {
        hsHeader,
        hsFooter
    },
    methods:{
        
    },
}
</script>

<style amp-boilerplate>body{-webkit-animation:-amp-start 8s steps(1,end) 0s 1 normal both;-moz-animation:-amp-start 8s steps(1,end) 0s 1 normal both;-ms-animation:-amp-start 8s steps(1,end) 0s 1 normal both;animation:-amp-start 8s steps(1,end) 0s 1 normal both}@-webkit-keyframes -amp-start{from{visibility:hidden}to{visibility:visible}}@-moz-keyframes -amp-start{from{visibility:hidden}to{visibility:visible}}@-ms-keyframes -amp-start{from{visibility:hidden}to{visibility:visible}}@-o-keyframes -amp-start{from{visibility:hidden}to{visibility:visible}}@keyframes  -amp-start{from{visibility:hidden}to{visibility:visible}}</style><noscript><style amp-boilerplate>body{-webkit-animation:none;-moz-animation:none;-ms-animation:none;animation:none}</style></noscript> 
