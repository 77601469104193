<template>
    <section data-section-10="10">
        <footer class="footer-3">
            <div class="footer-center">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4">
                            <div class="widget">
                                <div class="widget-header">
                                    <h3>About Us</h3>
                                </div>
                                <!-- <div class="widget-body" v-html="info?.summary"></div> -->
                                <div class="widget-body">
                                    <p>WISCO-HGlaser, an authoritative manufacturer of high power laser large equipment, provides laser surface treatment and re-manufacturing application solutions.</p>
                                    <ul class="contact-list">
                                        <li><i><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M570.24 247.41L512 199.52V104a8 8 0 0 0-8-8h-32a8 8 0 0 0-7.95 7.88v56.22L323.87 45a56.06 56.06 0 0 0-71.74 0L5.76 247.41a16 16 0 0 0-2 22.54L14 282.25a16 16 0 0 0 22.53 2L64 261.69V448a32.09 32.09 0 0 0 32 32h128a32.09 32.09 0 0 0 32-32V344h64v104a32.09 32.09 0 0 0 32 32h128a32.07 32.07 0 0 0 32-31.76V261.67l27.53 22.62a16 16 0 0 0 22.53-2L572.29 270a16 16 0 0 0-2.05-22.59zM463.85 432H368V328a32.09 32.09 0 0 0-32-32h-96a32.09 32.09 0 0 0-32 32v104h-96V222.27L288 77.65l176 144.56z"></path></svg></i>
                                        <p>Laser Industrial Park, Huazhong University of Science and Technology，Hust Industry District East Lake ，Wuhan，Hubei，P.R.China</p>
                                        </li>
                                        <li><i><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M476.5 22.9L382.3 1.2c-21.6-5-43.6 6.2-52.3 26.6l-43.5 101.5c-8 18.6-2.6 40.6 13.1 53.4l40 32.7C311 267.8 267.8 311 215.4 339.5l-32.7-40c-12.8-15.7-34.8-21.1-53.4-13.1L27.7 329.9c-20.4 8.7-31.5 30.7-26.6 52.3l21.7 94.2c4.8 20.9 23.2 35.5 44.6 35.5C312.3 512 512 313.7 512 67.5c0-21.4-14.6-39.8-35.5-44.6zM69.3 464l-20.9-90.7 98.2-42.1 55.7 68.1c98.8-46.4 150.6-98 197-197l-68.1-55.7 42.1-98.2L464 69.3C463 286.9 286.9 463 69.3 464z"></path></svg></i><a href="tel:(+86)）027-87180285" rel="nofollow">(+86)027-87180285</a></li>
                                        <li><i><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M480 136.88v-30.37c0-16.97-6.74-33.25-18.74-45.26l-42.51-42.51A64.037 64.037 0 0 0 373.49 0H174.48C157.64 0 144 14.33 144 32v104.88c-9.45-5.5-20.28-8.88-32-8.88H64c-35.35 0-64 28.65-64 64v256c0 35.35 28.65 64 64 64h48c15.22 0 29.01-5.54 40-14.41 10.99 8.87 24.78 14.41 40 14.41h256c35.35 0 64-28.65 64-64V192c0-23.63-12.95-44.04-32-55.12zM128 448c0 8.82-7.18 16-16 16H64c-8.82 0-16-7.18-16-16V192c0-8.82 7.18-16 16-16h48c8.82 0 16 7.18 16 16v256zm64-400h176v48c0 8.84 7.16 16 16 16h48v80H192V48zm272 400c0 8.82-7.18 16-16 16H192c-8.82 0-16-7.18-16-16V240h288v208zM352 336h32c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16zm-96 96h32c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16zm0-96h32c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16zm96 96h32c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16z"></path></svg></i><a href="tel:(+86)027-87180285" rel="nofollow">(+86)027-87180211</a></li>
                                        <li><i><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm0 48v40.805c-22.422 18.259-58.168 46.651-134.587 106.49-16.841 13.247-50.201 45.072-73.413 44.701-23.208.375-56.579-31.459-73.413-44.701C106.18 199.465 70.425 171.067 48 152.805V112h416zM48 400V214.398c22.914 18.251 55.409 43.862 104.938 82.646 21.857 17.205 60.134 55.186 103.062 54.955 42.717.231 80.509-37.199 103.053-54.947 49.528-38.783 82.032-64.401 104.947-82.653V400H48z"></path></svg></i><a href="mailto:info@wghglaser.com" rel="nofollow">info@wghglaser.com</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="widget">
                                <div class="widget-header">
                                    <h3>Products</h3>
                                </div>
                                <div class="widget-body">
                                    <ul class="list"  v-for="(item, index) in m_list" :key="index">
                                        <li><a :href="`/product/${item.nodeId}`">{{ item.nodeName }}</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="widget">
                                <div class="widget-header">
                                    <h3>Latest News</h3>
                                </div>
                                <div class="widget-body">
                                    <ul class="media-list">
                                        <li class="media" v-for="(item, index) in newsList" @click="newsdetails(item)" :key="index">
                                            <div class="media-image wh-60">
                                                <a href="javascript:;"
                                                    :title="item.title">
                                                    <amp-img
                                                        :src="`${item.imageUrl}&v=${index}`"
                                                        :key="item.id"
                                                        width="60" height="60" layout="responsive"
                                                        :alt="item.title"
                                                        class="i-amphtml-element i-amphtml-layout-responsive i-amphtml-layout-size-defined i-amphtml-built i-amphtml-layout"
                                                        i-amphtml-layout="responsive"><i-amphtml-sizer
                                                            slot="i-amphtml-svc"
                                                            style="padding-top: 100%;"></i-amphtml-sizer><img
                                                            decoding="async"
                                                            :alt="item.title"
                                                            :src="`${item.imageUrl}&v=${index}`"
                                                            class="i-amphtml-fill-content i-amphtml-replaced-content"></amp-img>
                                                </a>
                                            </div>
                                            <div class="media-body">
                                                <h5><a
                                                        href="javascript:;">{{ item.title }}</a></h5>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12">
                            <div class="tag-list">
                                <ul>
                                    <li><a href="/aboutUs/3862">About us</a></li>
                                    <li><a href="/aboutUs/3863">Corporate highlights</a></li>
                                    <li><a href="/aboutUs/3864">Certification</a></li>
                                    <li><a href="/aboutUs/3866">Choose us</a></li>
                                    <li><a href="/aboutUs/3867">Service support</a></li>

                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="footer-bottom">
                <div class="container">
                    Copyright 2020 WuHan WISCO-HG Laser Large Scale Equipment co.,Ltd. All Rights Reserved
                </div>
            </div>
        </footer>


    </section>
</template>
<script>
import {
    API_contentList,
    API_GetChildMenuCon,
    API_GetNewsCon
} from "@/fetch/api"
export default {
    data () {
        return {
            url: 'https://www.wghglaser.com/',
            newsList:[],
            m_list:[],
            info:null
        }
    },

    created () {
        this.GetSecMenuCon()
        this.getNewsList()
        this.getInfo()
    },

    mounted () {

    },
    methods: {
        getInfo () {
            API_GetNewsCon({
                contentId: 324805
            })
                .then(res => {
                    let info = res.data
                    this.info = info
                })
                .catch(error => { })
        },
        GetSecMenuCon () {
            API_GetChildMenuCon({
                nodeId: 3858
            })
                .then(res => {
                    let { childs } = res.data
                    this.m_list = childs
                })
                .catch(error => {
                    console.log('error:', error)
                })
        },
        getNewsList () {
            API_contentList({
                pageNo: 0,
                pageSize: 4,
                nodeId: 3882,
            })
                .then(res => {
                    let { data} = res.data
                    this.newsList = data
                })
                .catch(err => {
                    console.log(err)
                })
        },
        newsdetails (item) {
            this.$router.push({ path: `/article/${item.node.nodeId}/${item.id}` })
        },
        getLink () {
            this.$router.push(this.link)
        },
        scrollToTop () {
            const scrollStep = window.pageYOffset / 20
            // 创建一个定时器
            const scrollInterval = setInterval(() => {
                // 如果已经滚动到了顶部，清除定时器
                if (window.pageYOffset === 0) {
                    clearInterval(scrollInterval)
                }
                // 滚动到上一帧的位置
                window.scroll(0, window.pageYOffset - scrollStep)
            }, 15)
        }
    }
}
</script>