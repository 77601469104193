<template>
    <div>
        <!--头部公共部分-->
        <section data-section-2="2">
            <div id="top">
                <amp-position-observer on="enter:hideAnim.start; exit:showAnim.start" layout="nodisplay"
                    class="i-amphtml-element i-amphtml-layout-nodisplay i-amphtml-built" hidden=""
                    i-amphtml-layout="nodisplay"></amp-position-observer>
            </div>
            <section class="header-bottom-5 d-none d-lg-block" style="background: #eee;">
                <div class="container">
                    <div class="navbar justify-content-between">
                        <div class="navbar-brand">
                            <a href="/" class="logo-image">
                                <amp-img src="//v4-assets.goalsites.com/lizihao/amp/wugang/logo.png" width="250"
                                    height="56" layout="intrinsic" alt="comName logo"
                                    class="i-amphtml-element i-amphtml-layout-intrinsic i-amphtml-layout-size-defined i-amphtml-built i-amphtml-layout"
                                    i-amphtml-layout="intrinsic"><i-amphtml-sizer class="i-amphtml-sizer"
                                        slot="i-amphtml-svc"><img alt="" role="presentation" aria-hidden="true"
                                            class="i-amphtml-intrinsic-sizer"
                                            src="data:image/svg+xml;charset=utf-8,<svg height=&quot;56px&quot; width=&quot;250px&quot; xmlns=&quot;http://www.w3.org/2000/svg&quot; version=&quot;1.1&quot;/>"></i-amphtml-sizer><img
                                        decoding="async" alt="comName logo"
                                        src="//v4-assets.goalsites.com/lizihao/amp/wugang/logo.png"
                                        class="i-amphtml-fill-content i-amphtml-replaced-content"></amp-img>
                            </a>
                        </div>
                        <div class="navbar-nav">
                            <ul class="nav">
                                <li class="nav-item">
                                    <a class="nav-link" href="/"> Home </a>
                                </li>
                                <li v-for="(item, index) in datas" :key='index' class="nav-item dropdown">
                                    <a class="nav-link" href="javascript:;"
                                        @click="linkUrl(item, item.childs ? item.childs[0] : '', 1)"> {{ item.nodeName }}
                                        <i v-if="item.childs" class="fa fa-angle-down"></i>
                                    </a>
                                    <ul v-if="item.childs" class="dropdown-menu children">
                                        <li v-for="(citem, cindex) in item.childs" :key="cindex" class="nav-item">
                                            <a class="nav-link" href="javascript:;" @click="linkUrl(item, citem, 2)">{{
                                    citem.nodeName }}</a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        <div class="icon-btn-inline">
                            <ul class="nav justify-content-lg-end">
                                <li class="nav-item">
                                    <button class="btn-icon btn-o" role="button" on="tap:menu-search.toggle"><i><svg
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                <path
                                                    d="M508.5 468.9L387.1 347.5c-2.3-2.3-5.3-3.5-8.5-3.5h-13.2c31.5-36.5 50.6-84 50.6-136C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c52 0 99.5-19.1 136-50.6v13.2c0 3.2 1.3 6.2 3.5 8.5l121.4 121.4c4.7 4.7 12.3 4.7 17 0l22.6-22.6c4.7-4.7 4.7-12.3 0-17zM208 368c-88.4 0-160-71.6-160-160S119.6 48 208 48s160 71.6 160 160-71.6 160-160 160z">
                                                </path>
                                            </svg></i></button>

                                </li>
                                <li class="nav-item">
                                    <button class="btn-icon btn-o" role="button" on="tap:menu-language.toggle"><i><svg
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512">
                                                <path
                                                    d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm179.3 160h-67.2c-6.7-36.5-17.5-68.8-31.2-94.7 42.9 19 77.7 52.7 98.4 94.7zM248 56c18.6 0 48.6 41.2 63.2 112H184.8C199.4 97.2 229.4 56 248 56zM48 256c0-13.7 1.4-27.1 4-40h77.7c-1 13.1-1.7 26.3-1.7 40s.7 26.9 1.7 40H52c-2.6-12.9-4-26.3-4-40zm20.7 88h67.2c6.7 36.5 17.5 68.8 31.2 94.7-42.9-19-77.7-52.7-98.4-94.7zm67.2-176H68.7c20.7-42 55.5-75.7 98.4-94.7-13.7 25.9-24.5 58.2-31.2 94.7zM248 456c-18.6 0-48.6-41.2-63.2-112h126.5c-14.7 70.8-44.7 112-63.3 112zm70.1-160H177.9c-1.1-12.8-1.9-26-1.9-40s.8-27.2 1.9-40h140.3c1.1 12.8 1.9 26 1.9 40s-.9 27.2-2 40zm10.8 142.7c13.7-25.9 24.4-58.2 31.2-94.7h67.2c-20.7 42-55.5 75.7-98.4 94.7zM366.3 296c1-13.1 1.7-26.3 1.7-40s-.7-26.9-1.7-40H444c2.6 12.9 4 26.3 4 40s-1.4 27.1-4 40h-77.7z">
                                                </path>
                                            </svg></i></button>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
            </section>
            <section id="header-sticky" class="header-bottom-5 d-none d-lg-block" style="background-color: #eee;">
                <div class="container">
                    <div class="navbar justify-content-between">
                        <div class="navbar-brand">
                            <a href="http://en.wghglaser.com/" class="logo-image">
                                <amp-img src="//v4-assets.goalsites.com/lizihao/amp/wugang/logo.png" width="250"
                                    height="56" layout="intrinsic" alt="comName logo"
                                    class="i-amphtml-element i-amphtml-layout-intrinsic i-amphtml-layout-size-defined i-amphtml-built i-amphtml-layout"
                                    i-amphtml-layout="intrinsic"><i-amphtml-sizer class="i-amphtml-sizer"
                                        slot="i-amphtml-svc"><img alt="" role="presentation" aria-hidden="true"
                                            class="i-amphtml-intrinsic-sizer"
                                            src="data:image/svg+xml;charset=utf-8,<svg height=&quot;56px&quot; width=&quot;250px&quot; xmlns=&quot;http://www.w3.org/2000/svg&quot; version=&quot;1.1&quot;/>"></i-amphtml-sizer><img
                                        decoding="async" alt="comName logo"
                                        src="//v4-assets.goalsites.com/lizihao/amp/wugang/logo.png"
                                        class="i-amphtml-fill-content i-amphtml-replaced-content"></amp-img>
                            </a>
                        </div>
                        <div class="navbar-nav">
                            <ul class="nav">
                                <li class="nav-item">
                                    <a class="nav-link" href="/"> Home </a>
                                </li>
                                <li v-for="(item, index) in datas" :key='index' class="nav-item dropdown">
                                    <a class="nav-link" href="javascript:;"
                                        @click="linkUrl(item, item.childs ? item.childs[0] : '', 1)">{{ item.nodeName
                                        }}<i v-if="item.childs" class="fa fa-angle-down"></i>
                                    </a>
                                    <ul v-if="item.childs" class="dropdown-menu children">
                                        <li v-for="(citem, cindex) in item.childs" :key="cindex" class="nav-item">
                                            <a class="nav-link" href="javascript:;" @click="linkUrl(item, citem, 2)">{{
                                    citem.nodeName }}</a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        <div class="icon-btn-inline">
                            <ul class="nav justify-content-lg-end">
                                <li class="nav-item">
                                </li>
                                <li class="nav-item">
                                    <button class="btn-icon btn-o" role="button" on="tap:menu-language.toggle"><i><svg
                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512">
                                        <path
                                            d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm179.3 160h-67.2c-6.7-36.5-17.5-68.8-31.2-94.7 42.9 19 77.7 52.7 98.4 94.7zM248 56c18.6 0 48.6 41.2 63.2 112H184.8C199.4 97.2 229.4 56 248 56zM48 256c0-13.7 1.4-27.1 4-40h77.7c-1 13.1-1.7 26.3-1.7 40s.7 26.9 1.7 40H52c-2.6-12.9-4-26.3-4-40zm20.7 88h67.2c6.7 36.5 17.5 68.8 31.2 94.7-42.9-19-77.7-52.7-98.4-94.7zm67.2-176H68.7c20.7-42 55.5-75.7 98.4-94.7-13.7 25.9-24.5 58.2-31.2 94.7zM248 456c-18.6 0-48.6-41.2-63.2-112h126.5c-14.7 70.8-44.7 112-63.3 112zm70.1-160H177.9c-1.1-12.8-1.9-26-1.9-40s.8-27.2 1.9-40h140.3c1.1 12.8 1.9 26 1.9 40s-.9 27.2-2 40zm10.8 142.7c13.7-25.9 24.4-58.2 31.2-94.7h67.2c-20.7 42-55.5 75.7-98.4 94.7zM366.3 296c1-13.1 1.7-26.3 1.7-40s-.7-26.9-1.7-40H444c2.6 12.9 4 26.3 4 40s-1.4 27.1-4 40h-77.7z">
                                        </path>
                                    </svg></i></button>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
            </section>
            <section class="header-mobile-1 d-lg-none">
                <div class="container-fluid">
                    <div class="navbar ">
                        <div class="nav-item">
                            <button class="btn-icon btn-o" role="button" on="tap:selector-menu.toggle"><i><svg
                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path
                                            d="M436 124H12c-6.627 0-12-5.373-12-12V80c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12zm0 160H12c-6.627 0-12-5.373-12-12v-32c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12zm0 160H12c-6.627 0-12-5.373-12-12v-32c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12z">
                                        </path>
                                    </svg></i></button>
                        </div>
                        <div class="navbar-brand">
                            <a href="http://en.wghglaser.com/" class="logo-image">
                                <amp-img style="max-width: 160px; --loader-delay-offset: 210ms !important;"
                                    src="//v4-assets.goalsites.com/lizihao/amp/wugang/logo.png" width="250" height="56"
                                    layout="intrinsic" alt="digood logo"
                                    class="i-amphtml-element i-amphtml-layout-intrinsic i-amphtml-layout-size-defined i-amphtml-built i-amphtml-layout"
                                    i-amphtml-layout="intrinsic"><i-amphtml-sizer class="i-amphtml-sizer"
                                        slot="i-amphtml-svc"><img alt="" role="presentation" aria-hidden="true"
                                            class="i-amphtml-intrinsic-sizer"
                                            src="data:image/svg+xml;charset=utf-8,<svg height=&quot;56px&quot; width=&quot;250px&quot; xmlns=&quot;http://www.w3.org/2000/svg&quot; version=&quot;1.1&quot;/>"></i-amphtml-sizer><img
                                        decoding="async" alt="digood logo"
                                        src="//v4-assets.goalsites.com/lizihao/amp/wugang/logo.png"
                                        class="i-amphtml-fill-content i-amphtml-replaced-content"></amp-img>
                            </a>
                        </div>
                        <ul class="nav justify-content-lg-end icon-btn-inline">

                            <li class="nav-item">
                                <button class="btn-icon btn-o" role="button" on="tap:menu-search.toggle"><i><svg
                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path
                                        d="M508.5 468.9L387.1 347.5c-2.3-2.3-5.3-3.5-8.5-3.5h-13.2c31.5-36.5 50.6-84 50.6-136C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c52 0 99.5-19.1 136-50.6v13.2c0 3.2 1.3 6.2 3.5 8.5l121.4 121.4c4.7 4.7 12.3 4.7 17 0l22.6-22.6c4.7-4.7 4.7-12.3 0-17zM208 368c-88.4 0-160-71.6-160-160S119.6 48 208 48s160 71.6 160 160-71.6 160-160 160z">
                                    </path>
                                </svg></i></button>
                            </li>
                            <li class="nav-item">
                                <button class="btn-icon btn-o" role="button" on="tap:menu-language.toggle"><i><svg
                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512">
                                    <path
                                        d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm179.3 160h-67.2c-6.7-36.5-17.5-68.8-31.2-94.7 42.9 19 77.7 52.7 98.4 94.7zM248 56c18.6 0 48.6 41.2 63.2 112H184.8C199.4 97.2 229.4 56 248 56zM48 256c0-13.7 1.4-27.1 4-40h77.7c-1 13.1-1.7 26.3-1.7 40s.7 26.9 1.7 40H52c-2.6-12.9-4-26.3-4-40zm20.7 88h67.2c6.7 36.5 17.5 68.8 31.2 94.7-42.9-19-77.7-52.7-98.4-94.7zm67.2-176H68.7c20.7-42 55.5-75.7 98.4-94.7-13.7 25.9-24.5 58.2-31.2 94.7zM248 456c-18.6 0-48.6-41.2-63.2-112h126.5c-14.7 70.8-44.7 112-63.3 112zm70.1-160H177.9c-1.1-12.8-1.9-26-1.9-40s.8-27.2 1.9-40h140.3c1.1 12.8 1.9 26 1.9 40s-.9 27.2-2 40zm10.8 142.7c13.7-25.9 24.4-58.2 31.2-94.7h67.2c-20.7 42-55.5 75.7-98.4 94.7zM366.3 296c1-13.1 1.7-26.3 1.7-40s-.7-26.9-1.7-40H444c2.6 12.9 4 26.3 4 40s-1.4 27.1-4 40h-77.7z">
                                    </path>
                                </svg></i><i><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                    <path
                                        d="M151.5 347.8L3.5 201c-4.7-4.7-4.7-12.3 0-17l19.8-19.8c4.7-4.7 12.3-4.7 17 0L160 282.7l119.7-118.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17l-148 146.8c-4.7 4.7-12.3 4.7-17 0z">
                                    </path>
                                </svg></i></button>
                            </li>
                        </ul>
                    </div>
                </div>

            </section>
            <amp-animation id="showAnim" layout="nodisplay"
                class="i-amphtml-element i-amphtml-layout-nodisplay i-amphtml-built" hidden=""
                i-amphtml-layout="nodisplay">
                <script type="application/json">
                    {
                        "duration": "200ms",
                        "fill": "both",
                        "iterations": "1",
                        "direction": "alternate",
                        "animations": [{
                            "selector": "#scrollToTopButton,#header-sticky",
                            "keyframes": [{
                                "opacity": "1",
                                "visibility": "visible"
                            }]
                        }]
                    }
                </script>
            </amp-animation>
            <amp-animation id="hideAnim" layout="nodisplay"
                class="i-amphtml-element i-amphtml-layout-nodisplay i-amphtml-built" hidden=""
                i-amphtml-layout="nodisplay">
                <script type="application/json">
                    {
                        "duration": "200ms",
                        "fill": "both",
                        "iterations": "1",
                        "direction": "alternate",
                        "animations": [{
                            "selector": "#scrollToTopButton,#header-sticky",
                            "keyframes": [{
                                "opacity": "0",
                                "visibility": "hidden"
                            }]
                        }]
                    }
                </script>
            </amp-animation>
            <amp-sidebar id="selector-menu" class="sidebar-nav-4" layout="nodisplay" side="left">
                <br/>
                <div class="sidebar-header">
                    <div class="accordion-link">
                        <h4 class="col text-uppercase m-0">Menu</h4>
                        <button role="button" class="btn-o btn-close" on="tap:selector-menu.close"><i><svg
                                    xmlns="http://www.w3.org/2000/svg" viewbox="0 0 12.75 12.75">
                                    <path
                                        d="M12.734,11.324 L11.320,12.738 L6.370,7.788 L1.420,12.738 L0.006,11.324 L4.956,6.373 L0.006,1.424 L1.420,0.009 L6.370,4.959 L11.320,0.009 L12.734,1.424 L7.784,6.373 L12.734,11.324 Z"
                                        class="an_cls-1"></path>
                                </svg></i></button>
                    </div>
                </div>
                <div class="sidebar-body menu_box">
                    <!-- <amp-accordion animate=""> -->
                        <section>
                            <h4 class="accordion-header">
                                <div class="accordion-link">
                                    <a href="/">Home</a>
                                </div>
                            </h4>
                            <div></div>
                        </section>
                        <section  v-for="(item, index) in datas" :key="index">
                            <h4 class="accordion-header">
                                <div class="accordion-link">
                                    <a @click="linkUrlM(item, item.childs ? item.childs[0] : '', 1)" href="javascript:;">{{item.nodeName}}</a>
                                    <i @click="menu_change(index)" v-if="item.childs"><svg xmlns="//www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path
                                            d="M441.9 167.3l-19.8-19.8c-4.7-4.7-12.3-4.7-17 0L224 328.2 42.9 147.5c-4.7-4.7-12.3-4.7-17 0L6.1 167.3c-4.7 4.7-4.7 12.3 0 17l209.4 209.4c4.7 4.7 12.3 4.7 17 0l209.4-209.4c4.7-4.7 4.7-12.3 0-17z">
                                        </path>
                                    </svg></i>
                                </div>
                            </h4>
                            <!-- <amp-accordion animate="" > -->
                                <!-- :class="item.unfold? 'menu_down':'menu_child'" -->
                            <section v-if="item.unfold">
                                <div  v-for="(citem, cindex) in item.childs" :key="cindex">
                                    <h4 class="accordion-header">
                                        <div class="accordion-link">
                                            <a @click="linkUrlM(item, citem, 2)" href="javascript:;">{{citem.nodeName}}</a>
                                        </div>
                                    </h4>
                                    <!-- <div></div> -->
                                </div>
                            </section>
                            
                            <!-- </amp-accordion> -->
                        </section>
                    <!-- </amp-accordion> -->
                </div>
            </amp-sidebar>
            <!-- <amp-sidebar id="selector-menu" :hidden="!isSidebarOpen"
                class="sidebar-nav-4 i-amphtml-element i-amphtml-layout-nodisplay i-amphtml-overlay i-amphtml-scrollable i-amphtml-built"
                layout="nodisplay" side="left" :class="{ 'custom-sidebar': isSidebarOpen }" i-amphtml-layout="nodisplay"
                role="menu" tabindex="-1">
                <div class="sidebar-header">
                    <div class="accordion-link">
                        <h4 class="col text-uppercase m-0">Menu</h4>
                        <button role="button" class="btn-o btn-close" on="tap:selector-menu.close"
                            @click="closeSidebar"><i><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.75 12.75">
                                    <path
                                        d="M12.734,11.324 L11.320,12.738 L6.370,7.788 L1.420,12.738 L0.006,11.324 L4.956,6.373 L0.006,1.424 L1.420,0.009 L6.370,4.959 L11.320,0.009 L12.734,1.424 L7.784,6.373 L12.734,11.324 Z"
                                        class="an_cls-1"></path>
                                </svg></i></button>
                    </div>
                </div>
                <div class="sidebar-body">
                    <amp-accordion animate="" class="i-amphtml-element i-amphtml-layout-container i-amphtml-built"
                        i-amphtml-layout="container">
                        <section>
                            <h4 class="accordion-header i-amphtml-accordion-header" id="79_AMP_header_0" role="button"
                                aria-controls="79_AMP_content_0" aria-expanded="false" tabindex="0">
                                <div class="accordion-link">
                                    <a href="/">Home</a>
                                </div>
                            </h4>
                            <div class="i-amphtml-accordion-content" id="79_AMP_content_0"
                                aria-labelledby="79_AMP_header_0" role="region"></div>
                        </section>

                        <section @click="linkUrl(item, item.childs ? item.childs[0] : '', 1)" v-for="(item, index) in datas"
                            :key="index">
                            <h4 class="accordion-header i-amphtml-accordion-header" id="73_AMP_header_4" role="button"
                                aria-controls="73_AMP_content_4" aria-expanded="false" tabindex="0">
                                <div class="accordion-link">
                                    <a href="javascript:;">{{ item.nodeName }}</a>
                                    <i v-if="item.childs"><svg xmlns="//www.w3.org/2000/svg" viewBox="0 0 448 512">
                                            <path
                                                d="M441.9 167.3l-19.8-19.8c-4.7-4.7-12.3-4.7-17 0L224 328.2 42.9 147.5c-4.7-4.7-12.3-4.7-17 0L6.1 167.3c-4.7 4.7-4.7 12.3 0 17l209.4 209.4c4.7 4.7 12.3 4.7 17 0l209.4-209.4c4.7-4.7 4.7-12.3 0-17z">
                                            </path>
                                        </svg></i>
                                </div>
                            </h4>
                            <amp-accordion animate
                                class="accordion-body i-amphtml-element i-amphtml-layout-container i-amphtml-accordion-content i-amphtml-built"
                                i-amphtml-layout="container" role="region">
                                <section v-for="(citem, cindex) in item.childs" :key="cindex">
                                    <h4 class="accordion-header i-amphtml-accordion-header" id="31_AMP_header_0"
                                        role="button" aria-controls="31_AMP_content_0" aria-expanded="false"
                                        tabindex="0">
                                        <div class="accordion-link" @click="linkUrl(item, citem, 2)">
                                            <a href="javascript:;">{{ citem.nodeName }}</a>
                                        </div>
                                    </h4>
                                    <div class="i-amphtml-accordion-content" id="31_AMP_content_0"
                                        aria-labelledby="31_AMP_header_0" role="region"></div>
                                </section>

                            </amp-accordion>
                        </section>
                    </amp-accordion>
                </div>
                <button class="i-amphtml-screen-reader" tabindex="-1">Close the sidebar</button>
            </amp-sidebar> -->
            <amp-sidebar id="menu-search"
                class="sidebar-menu-4 i-amphtml-element i-amphtml-layout-nodisplay i-amphtml-overlay i-amphtml-scrollable i-amphtml-built"
                layout="nodisplay" side="right" hidden="" i-amphtml-layout="nodisplay" role="menu" tabindex="-1">
                <div class="sidebar-header">
                    <div class="accordion-link">
                        <h2 class="col">Search Product</h2>
                        <button role="button" class="btn-o btn-close" on="tap:menu-search.close"><i><svg
                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.75 12.75">
                                    <path
                                        d="M12.734,11.324 L11.320,12.738 L6.370,7.788 L1.420,12.738 L0.006,11.324 L4.956,6.373 L0.006,1.424 L1.420,0.009 L6.370,4.959 L11.320,0.009 L12.734,1.424 L7.784,6.373 L12.734,11.324 Z"
                                        class="an_cls-1"></path>
                                </svg></i></button>
                    </div>
                </div>
                <div class="sidebar-body">
                    <form method="get" :action="`/product/3858`" target="_top">
                        <input type="text" v-model="keyword" name="keyword" class="form-control"
                            placeholder="Enter keywords here... ">
                        <input class="btn" type="submit" value="Search">
                    </form>
                </div>
                <button class="i-amphtml-screen-reader" tabindex="-1">Close the sidebar</button>
            </amp-sidebar>
            <amp-sidebar id="menu-language"
                class="sidebar-menu-4 i-amphtml-element i-amphtml-layout-nodisplay i-amphtml-overlay i-amphtml-scrollable i-amphtml-built"
                layout="nodisplay" side="left" hidden="" i-amphtml-layout="nodisplay" role="menu" tabindex="-1">
                <div class="sidebar-header">
                    <div class="accordion-link">
                        <h2 class="col">Choose Language</h2>
                        <button role="button" class="btn-o btn-close" on="tap:menu-language.close"><i><svg
                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.75 12.75">
                                    <path
                                        d="M12.734,11.324 L11.320,12.738 L6.370,7.788 L1.420,12.738 L0.006,11.324 L4.956,6.373 L0.006,1.424 L1.420,0.009 L6.370,4.959 L11.320,0.009 L12.734,1.424 L7.784,6.373 L12.734,11.324 Z"
                                        class="an_cls-1"></path>
                                </svg></i></button>
                    </div>
                </div>
                <div class="sidebar-body">
                    <ul class="language list-inline">
                        <li><a href="http://en.wghglaser.com/"><i><svg xmlns="http://www.w3.org/2000/svg"
                                        id="flag-icon-css-gb" viewBox="0 0 640 480">
                                        <path fill="#012169" d="M0 0h640v480H0z"></path>
                                        <path fill="#FFF"
                                            d="M75 0l244 181L562 0h78v62L400 241l240 178v61h-80L320 301 81 480H0v-60l239-178L0 64V0h75z">
                                        </path>
                                        <path fill="#C8102E"
                                            d="M424 281l216 159v40L369 281h55zm-184 20l6 35L54 480H0l240-179zM640 0v3L391 191l2-44L590 0h50zM0 0l239 176h-60L0 42V0z">
                                        </path>
                                        <path fill="#FFF" d="M241 0v480h160V0H241zM0 160v160h640V160H0z"></path>
                                        <path fill="#C8102E" d="M0 193v96h640v-96H0zM273 0v480h96V0h-96z"></path>
                                    </svg>
                                </i>English</a></li>
                        <li style="display:block"><a target="_blank" href="https://www.wghglaser.com"><i><svg
                                        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                        id="flag-icon-css-cn" viewBox="0 0 640 480">
                                        <defs>
                                            <path id="a" fill="#ffde00" d="M-.6.8L0-1 .6.8-1-.3h2z"></path>
                                        </defs>
                                        <path fill="#de2910" d="M0 0h640v480H0z"></path>
                                        <use width="30" height="20" transform="matrix(71.9991 0 0 72 120 120)"
                                            xlink:href="#a"></use>
                                        <use width="30" height="20"
                                            transform="matrix(-12.33562 -20.5871 20.58684 -12.33577 240.3 48)"
                                            xlink:href="#a"></use>
                                        <use width="30" height="20"
                                            transform="matrix(-3.38573 -23.75998 23.75968 -3.38578 288 95.8)"
                                            xlink:href="#a"></use>
                                        <use width="30" height="20"
                                            transform="matrix(6.5991 -23.0749 23.0746 6.59919 288 168)" xlink:href="#a">
                                        </use>
                                        <use width="30" height="20"
                                            transform="matrix(14.9991 -18.73557 18.73533 14.99929 240 216)"
                                            xlink:href="#a"></use>
                                    </svg>
                                </i>Chinese</a></li>
                    </ul>
                </div>
                <button class="i-amphtml-screen-reader" tabindex="-1">Close the sidebar</button>
            </amp-sidebar>
        </section>
    </div>
</template>
<script>
import {
    API_GetMenuList,
} from "@/fetch/api"
export default {
    data () {
        return {
            isActive: '',
            datas: [],
            keyword: '',
            isSidebarOpen: true
        }
    },
    $route () {
        // 每当路由发生变化时关闭侧边栏
        this.isSidebarOpen = false
    },
    beforeMount () {
        this.actives = this.$route.params.nodeId
        API_GetMenuList()
            .then(res => {
                let data = {
                    firstLevelMenuList: [],
                    firstLevelMenuListById: {},
                    secondLevelMenuListById: {},
                    menuList: []
                }
                let dataObj = res.data[0].childs
                dataObj.splice(-2, 2)
                dataObj.forEach(i => {
                    i.unfold=false
                })
                this.datas = dataObj
                data.menuList = dataObj
                this.$store.commit("dataObj", data)
            })
            .catch(error => {
                console.log(error)
            })

    },
    created () {
    },

    mounted () {
    },
    methods: {
        menu_change(index){
            this.datas[index].unfold = !this.datas[index].unfold
            console.log( this.datas)
        },
        closeSidebar () {
            this.isSidebarOpen = false
        },
        goSearch () {
            if (this.keyword != '') {
                this.$router.push(`/search?keyword=${this.keyword}`)
            }
        },
        linkUrl (item, citem, layer) {
            switch (item.nodeName) {
                case "Why Us":
                    this.$router.push({ name: 'aboutUs', params: { nodeId: citem.nodeId } })
                    break
                case "Products":
                    this.$router.push({ name: 'product', params: { nodeId: layer == 1 ? item.nodeId : citem.nodeId } })
                    break
                case "Solutions":
                    if (layer == 1) {
                        this.$router.push({ name: 'solutions', params: { nodeId: item.nodeId } })
                    } else {
                        this.$router.push({ name: 'solutionsDetails', params: { nodeId: citem.nodeId, id: '0' } })
                    }
                    break
                case "Media Center":
                    this.$router.push({ name: 'newsList', params: { nodeId: citem.nodeId } })
                    break
                case "Contact Us":
                    this.$router.push({ name: 'contactUs', params: { nodeId: item.nodeId } })
                    break
            }
            
        },
        linkUrlM (item, citem, layer) {
            let routeData = ''
            switch (item.nodeName) {
                case "Why Us":
                    // this.$router.push({ name: 'aboutUs', params: { nodeId: citem.nodeId } })
                    routeData = this.$router.resolve({
                        name: 'aboutUs', 
                        params:{ nodeId: citem.nodeId },
                        query: { }
                    });
                    // 打开新窗口
                    window.open(routeData.href, '_blank');
                    break
                case "Products":
                    // this.$router.push({ name: 'product', params: { nodeId: layer == 1 ? item.nodeId : citem.nodeId } })
                    routeData = this.$router.resolve({
                        name: 'product', 
                        params:{ nodeId: layer == 1 ? item.nodeId : citem.nodeId },
                        query: { }
                    });
                    // 打开新窗口
                    window.open(routeData.href, '_blank');
                    break
                case "Solutions":
                    if (layer == 1) {
                        // this.$router.push({ name: 'solutions', params: { nodeId: item.nodeId } })
                        routeData = this.$router.resolve({
                            name: 'solutions', 
                            params:{ nodeId: item.nodeId },
                            query: { }
                        });
                        // 打开新窗口
                        window.open(routeData.href, '_blank');
                        
                    } else {
                        // this.$router.push({ name: 'solutionsDetails', params: { nodeId: citem.nodeId, id: '0' } })
                        routeData = this.$router.resolve({
                            name: 'solutionsDetails', 
                            params:{ nodeId: citem.nodeId, id: '0' },
                            query: { }
                        });
                        // 打开新窗口
                        window.open(routeData.href, '_blank');
                    }
                    break
                case "Media Center":
                    // this.$router.push({ name: 'newsList', params: { nodeId: citem.nodeId } })
                    routeData = this.$router.resolve({
                            name: 'newsList', 
                            params:{ nodeId: citem.nodeId },
                            query: { }
                        });
                        // 打开新窗口
                        window.open(routeData.href, '_blank');
                    break
                case "Contact Us":
                    // this.$router.push({ name: 'contactUs', params: { nodeId: item.nodeId } })
                    routeData = this.$router.resolve({
                            name: 'contactUs', 
                            params: { nodeId: item.nodeId },
                            query: { }
                        });
                        // 打开新窗口
                        window.open(routeData.href, '_blank')
                    break
            }
            
        }
    }
}
</script>
<style scoped>
.sidebar-body {

}
.active {
    display: block
}
.menu_box a{
    font-weight: 400;
}
.menu_box > section{
    padding:.7rem;
    border-bottom: 1px solid #f0f0f0;
}
.menu_box > section section > div{
    padding:.7rem 1rem;
    border-bottom: 1px solid #f0f0f0;
}
.menu_box h4 {
    margin: 0!important;
}
.menu_child {
    height: 0;
}
.menu_down {
    height: auto;
}
</style>