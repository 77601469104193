import { render, staticRenderFns } from "./App.vue?vue&type=template&id=13b15c36"
import script from "./App.vue?vue&type=script&lang=js"
export * from "./App.vue?vue&type=script&lang=js"
import style0 from "./App.vue?vue&type=style&index=0&id=13b15c36&prod&amp-boilerplate=true&lang=css"


/* normalize component */
import normalizer from "!../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./App.vue?vue&type=custom&index=0&blockType=noscript"
if (typeof block0 === 'function') block0(component)

export default component.exports